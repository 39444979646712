import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import debounce from 'lodash.debounce';
import * as THREE from 'three';

import { useThree, useFrame } from "@react-three/fiber";
import { useXR } from "@react-three/xr";
import { useObjectStore } from "../zustand/objects";
import { useAccountStore } from "../zustand/account";

export function CaptureHeadset({ currentObjectArtboard }) {
  const setPreview = useObjectStore((state) => state.setPreview);
  const setHeadset = useObjectStore((state) => state.setHeadset);
  const setCursor = useObjectStore((state) => state.setCursor);
  const sendPresenceUpdateToChannel = useObjectStore((state) => state.sendPresenceUpdateToChannel);
  const uniqueRealtimeId = useObjectStore((state) => state.uniqueRealtimeId);
  const projectKey = useObjectStore((state) => state.projectKey);
  const { camera, raycaster, mouse, scene, gl } = useThree()
  const { player } = useXR()
  const { objectsAreLoaded } = useObjectStore()

  const xr = useXR();
  const prevMatrixWorld = useRef(null);

  const { firstName } = useAccountStore()

  // ATTEMPT AT COMPARING ROTATION AND POSITION INSTEAD OF MATRIX TO TRIGGER UPDATE
  const prevData = {
    rotation: {},
    position: {}
  }


  const updateObject = {
    field: "preview",
    updateType: "presenceUpdate",
    projectKey: projectKey,
    uniqueRealtimeId: uniqueRealtimeId,
    update: {
      preview: {
        rotation: new THREE.Euler(camera.rotation.x.toFixed(2), camera.rotation.y.toFixed(2), camera.rotation.z.toFixed(2), 'XYZ'),
        position: new THREE.Vector3(camera.position.x.toFixed(2), 1.75, camera.position.z.toFixed(2)),
        mouse: mouse,
        page: 'preview',
        device: 'vr',
        artboard: currentObjectArtboard,
        name: localStorage.getItem('firstName')
      }
    }
  }

  useEffect(() => {
    sendPresenceUpdateToChannel(updateObject)
  }, [])

  useEffect(() => {
    setCursor(null)
    xr.isPresenting && sendPresenceUpdateToChannel(updateObject)
    // just changed device from computer to vr 21 Sep
  }, [xr.isPresenting, currentObjectArtboard])




  useFrame(() => {
    if (xr.isPresenting) {
      if
        (camera.rotation.x.toFixed(2) !== prevData.rotation.x ||
        camera.rotation.y.toFixed(2) !== prevData.rotation.y ||
        camera.rotation.z.toFixed(2) !== prevData.rotation.z ||
        camera.position.x.toFixed(2) !== prevData.position.x ||
        camera.position.y.toFixed(2) !== prevData.position.y ||
        camera.position.z.toFixed(2) !== prevData.position.z) {

        handleCameraMoveSet(updateObject)
        prevData.position = new THREE.Vector3(camera.position.x.toFixed(2), 1.75, camera.position.z.toFixed(2))
        prevData.rotation = new THREE.Euler(camera.rotation.x.toFixed(2), camera.rotation.y.toFixed(2), camera.rotation.z.toFixed(2), 'XYZ')
      }
    }
  });

  const handleCameraMoveSet =
    useCallback(
      debounce(
        (e) =>
          sendPresenceUpdateToChannel(e)
        , 20)
    )
  return ('')
}

export function CaptureCamera({ projectKey, currentObjectArtboard }) {
  const setPreview = useObjectStore((state) => state.setPreview);
  const setHeadset = useObjectStore((state) => state.setHeadset);
  const setCursor = useObjectStore((state) => state.setCursor);
  const uniqueRealtimeId = useObjectStore((state) => state.uniqueRealtimeId);
  const sendPresenceUpdateToChannel = useObjectStore((state) => state.sendPresenceUpdateToChannel);
  const { camera, raycaster, mouse } = useThree()
  const { overTransformControls } = useObjectStore()

  const useMouseMove = (onMouseMove) => {
    useEffect(() => {
      // !overTransformControls && document.addEventListener("pointermove", onMouseMove)
      document.addEventListener("pointermove", onMouseMove)
      // console.log(onMouseMove)
      return () => {
        document.removeEventListener("pointermove", onMouseMove)
      }
    }, [onMouseMove])
  }

  const { firstName } = useAccountStore()

  const updateObject = {
    field: "preview",
    updateType: "presenceUpdate",
    projectKey: projectKey,
    uniqueRealtimeId: uniqueRealtimeId,
    update: {
      preview: {
        rotation: camera.rotation,
        position: raycaster.ray.origin,
        mouse: mouse,
        page: 'preview',
        device: 'computer',
        artboard: currentObjectArtboard,
        name: localStorage.getItem('firstName')
      }
    }
  }

  useEffect(() => {
    sendPresenceUpdateToChannel(updateObject)
  }, [])

  useMouseMove(e => {
    handleCameraMoveSet(updateObject)
  });


  useEffect(() => {
    sendPresenceUpdateToChannel(updateObject)
  }, [currentObjectArtboard])


  const handleCameraMoveSet =
    useCallback(
      debounce(
        (e) =>
          sendPresenceUpdateToChannel(e)
        , 20)
    )

  return ('')
}