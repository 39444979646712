import React, { Suspense, useEffect, useRef } from "react";

import { useObjectStore } from "../zustand/objects";
import { useThree } from "@react-three/fiber";

import ModelObject from "./ModelObject"


import {Button, ButtonTexture} from "./ModelFiles/UI/Button"
import {CurvedPanels, CurvedPanelsTexture} from "./ModelFiles/UI/CurvedPanels"
import Slider from "./ModelFiles/UI/Slider"
import {StackedSidebar, StackedSidebarTexture} from "./ModelFiles/UI/StackedSidebar"
import {FlatPanels, FlatPanelsTexture} from "./ModelFiles/UI/FlatPanels"
import Toggle from "./ModelFiles/UI/Toggle"
import UpwardsFacingPanel from "./ModelFiles/UI/UpwardsFacingPanel"
import {RoundedWindow, RoundedWindowTexture} from "./ModelFiles/UI/RoundedWindow"
import {RoundedTabBar,RoundedTabBarTexture} from "./ModelFiles/UI/RoundedTabBar"
import {RoundedToolbar, RoundedToolbarTexture} from "./ModelFiles/UI/RoundedToolbar"
import {TabBar,TabBarTexture} from "./ModelFiles/UI/TabBar"
import {Toolbar, ToolbarTexture} from "./ModelFiles/UI/Toolbar"
import {Window, WindowTexture} from "./ModelFiles/UI/Window"


import { ConeShape, ConeShapeTexture } from "./ModelFiles/Shape/ConeShape";
import { CubeShape, CubeShapeTexture } from "./ModelFiles/Shape/CubeShape";
import { CylinderShape, CylinderShapeTexture } from "./ModelFiles/Shape/CylinderShape";
import { RoundedBoxShape, RoundedBoxShapeTexture } from "./ModelFiles/Shape/RoundedBoxShape";
import { SphereShape, SphereShapeTexture } from "./ModelFiles/Shape/SphereShape";


export const GroupedModel = ({ orbitControls, position, rotation, scale, id, currentObjectArtboard, objectsAreLoaded, object, colour, category, pose, isLocked, matrixState }) => {

    const components = {
        cone: ConeShape,
        cube: CubeShape,
        cubeTexture: CubeShapeTexture,
        cylinder: CylinderShape,
        roundedBox: RoundedBoxShape,
        sphere: SphereShape
    };


    const { updateObjectMatrix, clearGroupedObjects, groupedObjects } = useObjectStore()
    const {scene} = useThree();

    // Correct! JSX type can be a capitalized variable.
    const SpecificStory = components[object];

    // first load of model url triggers network request.
    // subsequent loads of same model url don't
    // file is stored in disk cache

 
    const modelRef = useRef()

    useEffect(() => {
        Promise.resolve(scene.getObjectByName(id).remove(modelRef.current))
  .then(Promise.resolve(modelRef.current.applyMatrix4(matrixState)))
  .then(Promise.resolve(scene.getObjectByName(id).attach(modelRef.current)))
      },[]);

// need to change updateScale function so that it updates the matrix4

    if (!objectsAreLoaded) {
        return
    } else {
        return (
            <group
                ref={modelRef}
                
            >
                <Suspense fallback={null}>
                {(category === "shape" || category === "apple" ||  category === "background" || category === "microsoft" || category === "floatgridsUi" || category === "floatgridsLayout") ? SpecificStory && <SpecificStory story={object} colour={colour} pose={pose} />
                 : <ModelObject grouped={true} colour={colour} pose={pose} />}
                </Suspense>
            </group>
        );
    }
};

 





// SHAPETEXTURE TO BE PUT IN OWN OBJECT
export const GroupedShapeTexture = ({ orbitControls, undoing, position, rotation, scale, id, currentObjectArtboard, objectsAreLoaded, object, colour, category, pose, imageTexture, isLocked, matrixState }) => {

    const components = {
        cone: ConeShapeTexture,
        cube: CubeShapeTexture,
        cylinder: CylinderShapeTexture,
        roundedBox: RoundedBoxShapeTexture,
        sphere: SphereShapeTexture
    };

    const {scene} = useThree();
    const modelRef = useRef()


    useEffect(() => {
        Promise.resolve(scene.getObjectByName(id).remove(modelRef.current))
  .then(Promise.resolve(modelRef.current.applyMatrix4(matrixState)))
  .then(Promise.resolve(scene.getObjectByName(id).attach(modelRef.current)))
},[]);

    // Correct! JSX type can be a capitalized variable.
    const SpecificStory = components[object];

    // first load of model url triggers network request.
    // subsequent loads of same model url don't
    // file is stored in disk cache

    if (!objectsAreLoaded) {
        return <div></div>;
    } else {
        return (
            <group
            ref={modelRef}
                // matrix={new THREE.Matrix4().copy(matrixState)}
                // matrixWorld={new THREE.Matrix4().copy(matrixState)}
                scale={scale}
            >
                <Suspense fallback={null}>
                    <SpecificStory story={object} colour={colour} pose={pose} imageTexture={imageTexture} />
                </Suspense>
            </group>
               
        );
    }
}

// UIMODELTEXTURE TO BE PUT IN OWN OBJECT
export const GroupedUIModelTexture = ({ orbitControls, undoing, position, rotation, scale, id, currentObjectArtboard, objectsAreLoaded, object, colour, category, pose, imageTexture, isLocked, matrixState }) => {

    const components = {
        button: ButtonTexture,
        curvedPanels: CurvedPanelsTexture,
        flatPanels: FlatPanelsTexture,
        // slider: SliderTexture,
        stackedSidebar: StackedSidebarTexture,
        // toggle: ToggleTexture,
        // upwardsFacingPanel: UpwardsFacingPanelTexture,
        roundedWindow: RoundedWindow,
            roundedTabBar: RoundedTabBarTexture,
            roundedToolbar: RoundedToolbarTexture,
            tabBar: TabBarTexture,
            toolBar: ToolbarTexture,
            window: WindowTexture
    };

    const {scene} = useThree();
    const modelRef = useRef()


    useEffect(() => {
        Promise.resolve(scene.getObjectByName(id).remove(modelRef.current))
  .then(Promise.resolve(modelRef.current.applyMatrix4(matrixState)))
  .then(Promise.resolve(scene.getObjectByName(id).attach(modelRef.current)))
},[]);

    // Correct! JSX type can be a capitalized variable.
    const SpecificStory = components[object];

    // first load of model url triggers network request.
    // subsequent loads of same model url don't
    // file is stored in disk cache

    if (!objectsAreLoaded) {
        return <div></div>;
    } else {
        return (
            <group
            ref={modelRef}
                // matrix={new THREE.Matrix4().copy(matrixState)}
                // matrixWorld={new THREE.Matrix4().copy(matrixState)}
                scale={scale}
            >
                <Suspense fallback={null}>
                    <SpecificStory story={object} colour={colour} pose={pose} imageTexture={imageTexture} />
                </Suspense>
            </group>
               
        );
    }
}


