import React, { useRef, useState, useEffect } from "react";
import {GroupedTextObject} from "./TextObject"
import { GroupedModel, GroupedShapeTexture, GroupedUIModelTexture } from "./GroupedModel";
import {GroupedImageObject} from "./ImageObject";
import { PivotControls, useCamera } from "@react-three/drei";
import { useObjectStore } from "../zustand/objects";
import * as THREE from "three";
import { Raycaster } from 'three';

import { useThree } from "@react-three/fiber";


export const GroupedObjectsMapped = ({
  orbitControls,
  objectDetails,
  objectsAreLoaded,
  currentObjectArtboard,
  undoing,
  artboards
  
}) => {
  const { updateObjectMatrix, projectKey, arrangeUndoRedo, groupedObjects } = useObjectStore()
  const {scene} = useThree();
  const [offset, setOffset] = useState(new THREE.Vector3(0,0,0))


  const groupRef = useRef();
  const yourMeshRef = useRef();
  const pivotControlsGizmoRef = useRef();

const updatePositions = () =>  groupRef.current.children[1].children.forEach(function(object) {

 // send current state to undoActions
 arrangeUndoRedo({
  updateType: "objectUpdate", 
  artboard: currentObjectArtboard, 
  objectID: object.name,
  undo:false, 
  projectKey: projectKey, 
  field: "matrixState",
  })
  // send update to zustand
  updateObjectMatrix({id: object.name, currentObjectArtboard: currentObjectArtboard, matrix: object.children[0].children[0].matrixWorld, undo: false})
  }) 




  if (!objectsAreLoaded) {
    return
  } else {
    return <PivotControls ref={groupRef} depthTest={false}
      lineWidth={5}
      scale={2}
      onDragEnd={() => updatePositions()}>
      {artboards[currentObjectArtboard].map((mapped) => (
        groupedObjects.includes(mapped.id) &&
          <group key={mapped.id}
          name={mapped.id}
          ref={yourMeshRef}
          // position={objectsAreLoaded && new THREE.Vector3().setFromMatrixPosition(mapped.matrixState)}
          >
             {mapped.category !== "text" ? (mapped.category === "image") ?
          <GroupedImageObject
            key={mapped.id}
            position={mapped.position}
            rotation={mapped.rotation}
            scale={mapped.scale}
            id={mapped.id}
            destination={mapped.destination}
            object={mapped.object}
            category={mapped.category}
            orbitControls={orbitControls}
            objectDetails={objectDetails}
            currentObjectArtboard={currentObjectArtboard}
            objectsAreLoaded={objectsAreLoaded}
            undoing={undoing}
            colour={mapped.colour}
            pose={mapped.pose}
            image={mapped.image}
            imageSize={mapped.imageSize}
            isLocked={mapped.isLocked}
            matrixState={mapped.matrixState}
            curved={mapped.curved}
            curveAmount={mapped.curveAmount}
          /> :
          (mapped.imageTexture && mapped.category === "UI") ?
             <GroupedUIModelTexture
              key={mapped.id}
              position={mapped.position}
              rotation={mapped.rotation}
              scale={mapped.scale}
              id={mapped.id}
              destination={mapped.destination}
              object={mapped.object}
              category={mapped.category}
              url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
              orbitControls={orbitControls}
              objectDetails={objectDetails}
              artboard={artboards[currentObjectArtboard]}
              currentObjectArtboard={currentObjectArtboard}
              objectsAreLoaded={objectsAreLoaded}
              undoing={undoing}
              colour={mapped.colour}
              pose={mapped.pose}
              imageTexture={mapped.imageTexture}
              isLocked={mapped.isLocked}
              matrixState={mapped.matrixState}
            />
            :
          (mapped.imageTexture && mapped.category === "shape") ?
             <GroupedShapeTexture
              key={mapped.id}
              position={mapped.position}
              rotation={mapped.rotation}
              scale={mapped.scale}
              id={mapped.id}
              destination={mapped.destination}
              object={mapped.object}
              category={mapped.category}
              url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
              orbitControls={orbitControls}
              objectDetails={objectDetails}
              artboard={artboards[currentObjectArtboard]}
              currentObjectArtboard={currentObjectArtboard}
              objectsAreLoaded={objectsAreLoaded}
              undoing={undoing}
              colour={mapped.colour}
              pose={mapped.pose}
              imageTexture={mapped.imageTexture}
              isLocked={mapped.isLocked}
              matrixState={mapped.matrixState}
            /> 
            :
          <GroupedModel
            position={mapped.position}
            rotation={mapped.rotation}
            scale={mapped.scale}
            id={mapped.id}
            destination={mapped.destination}
            object={mapped.object}
            category={mapped.category}
            url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
            orbitControls={orbitControls}
            objectDetails={objectDetails}
            artboard={artboards[currentObjectArtboard]}
            currentObjectArtboard={currentObjectArtboard}
            objectsAreLoaded={objectsAreLoaded}
            undoing={undoing}
            colour={mapped.colour}
            pose={mapped.pose}
            isLocked={mapped.isLocked}
            matrixState={mapped.matrixState}
          /> : <GroupedTextObject content={mapped.content}
          key={mapped.id}
          position={mapped.position}
          x={mapped.position.x}
          y={mapped.position.y}
          z={mapped.position.z}
          rotation={mapped.rotation}
          scale={mapped.scale}
          id={mapped.id}
          destination={mapped.destination}
          object={mapped.object}
          category={mapped.category}
          url={"./model/" + mapped.category + "/" + mapped.object + ".glb"}
          orbitControls={orbitControls}
          objectDetails={objectDetails}
          artboard={artboards[currentObjectArtboard]}
          currentObjectArtboard={currentObjectArtboard}
          objectsAreLoaded={objectsAreLoaded}
          undoing={undoing}
          colour={mapped.colour}
          isLocked={mapped.isLocked}
          matrixState={mapped.matrixState}
          pose={mapped.pose}
        />
    
        }
  </group>
        ))}

    </PivotControls>
  } 
};

