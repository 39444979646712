import React, {useCallback, useEffect} from "react";
import debounce from 'lodash.debounce';

import { useThree, useFrame } from "@react-three/fiber";
import { useObjectStore } from "../zustand/objects";
import { useAccountStore } from "../zustand/account";
 
export default function CaptureCursor({currentObjectArtboard}) {
  const setCursor = useObjectStore((state) => state.setCursor);
  const setPreview = useObjectStore((state) => state.setPreview);
  const sendPresenceUpdateToChannel = useObjectStore((state) => state.sendPresenceUpdateToChannel);
  const uniqueRealtimeId = useObjectStore((state) => state.uniqueRealtimeId);
  const projectKey = useObjectStore((state) => state.projectKey);

  const {isMenuOpen} = useObjectStore()
  const { camera, raycaster, mouse } = useThree()
 
  const useMouseMove = (onMouseMove) => {
    useEffect(() => {
       
      // !isMenuOpen && document.getElementById("viewport_canvas").addEventListener("pointermove", onMouseMove)
      !isMenuOpen && document.addEventListener("pointermove", onMouseMove)

        return () => {
          // document.getElementById("viewport_canvas").removeEventListener("pointermove", onMouseMove)
            document.removeEventListener("pointermove", onMouseMove)
        }
    }, [onMouseMove])
}

useEffect(() => {
  setPreview(null)
},[]) 

const updateObject = {
  field: "cursor",
  updateType: "presenceUpdate",
  projectKey: projectKey,
  uniqueRealtimeId: uniqueRealtimeId,
  update: {
    cursor: {
      rotation: camera.rotation, 
      position: raycaster.ray.origin, 
      mouse: mouse, 
      page: 'editor', 
      device: 'computer', 
      artboard: currentObjectArtboard, 
      name: localStorage.getItem('firstName') 
    }
  } 
}

useMouseMove(e => {
  handlePointerMoveSet(updateObject)
});

// I think it happened when changing artboard, maybe it's caused by changing artboard and not yet having a rotation
// useEffect(() => {
//   handlePointerMoveSet({ rotation: camera.rotation, position: raycaster.ray.origin, mouse: mouse, page: 'editor', device: 'computer', artboard: currentObjectArtboard, name: localStorage.getItem('firstName')  })
// },[currentObjectArtboard])

// also seems to occur from adding objects while texture active on something
// there is an error associated to line 1424 in object.js, which is the save function of adding an object

// IT LOOKS LIKE ITS THE SETCURSOR FUNCTION THAT MAKES STUFF GO WRONG. I'VE COMMENTED OUT handlePointerMoveSet below
// and it seems to be unnecessary (might not be)


 
const handlePointerMoveSet = 
useCallback(
    debounce(
      (e) =>
        sendPresenceUpdateToChannel(e)
    , 20 )
    )

 return ('') 
}